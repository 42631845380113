import { useState }                       from "react";
import {
  AppBar,
  Button,
  IconButton,
  Stack,
  Toolbar as ToolbarMUI,
}                                         from "@mui/material";
import { Box }                            from "@mui/material";
import { Icon }                           from "components/Icons";
import { useBreak }                       from "hooks/useBreak";
import Dropdown                           from "components/mui/Dropdown";
import DropdownItem                       from "components/mui/DropdownItem";
import { useAtom }                        from "jotai";
import React, { useRef }                  from "react";
import { toggle_drawer }                  from "state/actions";
import { active_project, fav_grid }       from "state/store.global";
import { getProjectData, setProjectData } from "state/jotai.projectDataHooks";
import { applysorting }                   from "utilities/utility.sort";
import { getFocus, useGet }               from "state/jotai";
import { _project }                       from "state/store.projects";
import { useFavoriteUnits }               from "./useFavoriteUnits";

const Toolbar = () => {
  const { units: favUnits } = useFavoriteUnits();

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const isDesktop = useBreak("md_up");

  return (
    <AppBar
      position  = "static"
      elevation = {0}
      sx        = {{ top: 150, bgcolor: "transparent", color: "text.primary" }}
    >
      <ToolbarMUI sx = {{ justifyContent: "space-between", padding: { xs: "0 20px"} }}>
        {!isDesktop && <Filter />}
        <LayoutSwitcher />
        { isDesktop && <Box>
          {
            buttonsLabel?.filter(
              (btn) => btn.UILabelProperty === "Yndlingsoverskrift1"
            )[0]?.Label
          }
          <strong>
          {` ${favUnits?.length} `}
            {favUnits.length > 1
              ? ` ${
                  buttonsLabel?.filter(
                    (btn) => btn.UILabelProperty === "boliger"
                  )[0]?.Label
                } `
              :  `${
                  buttonsLabel?.filter(
                    (btn) => btn.UILabelProperty === "bolig"
                  )[0]?.Label
                } `}
          </strong>
          {
            buttonsLabel?.filter(
              (btn) => btn.UILabelProperty === "Yndlingsoverskrift2"
            )[0]?.Label
          }
        </Box>}
        <Sorter />
      </ToolbarMUI>
    </AppBar>
  );
};

const Filter = () => {
  const [, toggleDrawer] = useAtom(toggle_drawer);

  const handleClick = () => {
          // TODO: Niklas - Handle Filter
    toggleDrawer("filter");
  };

  return (
    <Button
      color     = "inherit"
      startIcon = {<Icon icon="filter" />}
      onClick   = {handleClick}
      sx        = {{
        fontWeight: 400,
        fontSize  : "1rem",
      }}
    >
      Filter
    </Button>
  );
};

const LayoutSwitcher = () => {
  const [isGrid, setIsGrid] = useAtom(fav_grid);
  const handleClickList     = () => {
    setIsGrid(false);
  };
  const handleClickGrid = () => {
    setIsGrid(true);
  };

  return (
    <Stack direction = "row" spacing = {0}>
      <IconButton
        onClick = {handleClickList}
        color   = "inherit"
        sx      = {{ opacity: !isGrid ? 1 : 0.4 }}
      >
        <Icon icon = "list" />
      </IconButton>
      <IconButton
        onClick = {handleClickGrid}
        color   = "inherit"
        sx      = {{ opacity: isGrid ? 1 : 0.4 }}
      >
        <Icon icon = "grid" sx = {{ fontSize: "0.875em" }} />
      </IconButton>
    </Stack>
  );
};

const Sorter = () => {
  const dropdownRef = useRef(null);

  const options = getProjectData("sorteroptions", 0, []);
  const active  = getProjectData("activesorting");
  const units_  = getProjectData("units");
  const raws_   = getProjectData("unitsraw");

  const setSort  = setProjectData("activesorting");
  const setUnits = setProjectData("units");
  const setRaws  = setProjectData("unitsraw");

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const isDesktop = useBreak("md_up");

  const id                                = active.id;
  const { Units, ...rest }                = units_;
  const { Units: Raws, ...wrest }         = raws_;
  const [selectedLabel, setSelectedLabel] = useState(active.label);

  const handleSelect = ({ ID, Label, OrderOn, DefaultDirection }) => {
    const sorting = {
      key      : OrderOn,
      direction: DefaultDirection,
      id       : ID,
      label    : Label,
    };

    setSelectedLabel(Label);

    const units = applysorting(Units, sorting);
    const raws  = applysorting(Raws, sorting);

    setSort(sorting);
    setUnits({ ...rest, Units: units });
    setRaws({ ...wrest, Units: raws });

    dropdownRef.current.closeMenu();
  };

  return (
    <>
      <Dropdown
        width={isDesktop? "300px" : "auto"}
        ref        = {dropdownRef}
        buttonText = {
          buttonsLabel?.filter((btn) => btn?.UILabelProperty === "Sorter")[0]
            ?.Label
        }
        selectedLabel = {isDesktop && active?.label}
      >
        {/* TODO: Niklas - handle sort options */}
        {options.map((option, i) => (
          <DropdownItem
            key      = {i}
            onClick  = {() => handleSelect(option)}
            selected = {option.ID === id}
          >
            {option.Label}
          </DropdownItem>
        ))}
      </Dropdown>
    </>
  );
};

export default Toolbar;
