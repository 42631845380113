import FavoriteBorderOutlinedIcon     from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon           from "@mui/icons-material/FavoriteOutlined";
import { Box, Button, IconButton }    from "@mui/material";
import { Icon }                       from "components/Icons";
import PropTypes                      from "prop-types";
import React, { useEffect, useState } from "react";
import { getFocus, useAtom, useGet }  from "state/jotai";
import {
  active_project,
  favorites as stateFavorites,
}                                     from "state/store.global";
import { _project }                   from "state/store.projects";

function ButtonFavorite({ unitId, iconOnly }) {
  const [favorites, setFavorites]     = useAtom(stateFavorites);
  const [isFavorited, setIsFavorited] = useState(false);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  useEffect(() => {
    setIsFavorited(favorites.includes(unitId));
  }, [favorites, unitId]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (favorites.includes(unitId)) {
      setFavorites(favorites.filter((id) => id !== unitId));
      log({ action: "removed_apartment_from_favorites", unitId });
    } else {
      setFavorites([...favorites, unitId]);
      log({ action: "added_apartment_to_favorites", unitId });
    }
  };

  return (
    <>
      {iconOnly ? (
        <IconButton onClick = {handleClick}>
          {isFavorited ? (
            <FavoriteOutlinedIcon
              sx={{
                fill: "#863E30",
              }}
            />
          ) : (
            <FavoriteBorderOutlinedIcon
              sx={{
                fill: "#863E30",
              }}
            />
          )}
        </IconButton>
      ) : (
        <Button
          type      = "button"
          onClick   = {handleClick}
          color     = "inherit"
          startIcon = {
            <Icon
              icon = {favorites.includes(unitId) ? "heart-fill" : "heart"}
              sx   = {{ color: favorites.includes(unitId) ? "#863E30" : "inherit" }}
            />
          }
        >
          <Box
            component = "span"
            sx        = {{
              whiteSpace: "nowrap",
            }}
          >
            {favorites.includes(unitId)
              ? `${
                  buttonsLabel?.filter(
                    (btn) => btn.UILabelProperty === "DinFavorit"
                  )[0]?.Label
                }`
              : `${
                  buttonsLabel?.filter(
                    (btn) => btn.UILabelProperty === "Favorit"
                  )[0]?.Label
                }`}
          </Box>
        </Button>
      )}
    </>
  );
}

ButtonFavorite.propTypes = {
  unitId  : PropTypes.number.isRequired,
  iconOnly: PropTypes.bool,
};

export default ButtonFavorite;
