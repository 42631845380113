import { Box }   from "@mui/material";
import Downloads from "components/Downloads/Downloads";
import React     from "react";

export const ViewDownloads = () => {
  return (
    <Box
      sx={{
        pb: {md:"62px", sx:"62px"}
      }}
    >
      <Downloads />
    </Box>
  );
};
