import { Boolean }          from "./Filter.Controls.Boolean";
import { Interval }         from "./Filter.Controls.Interval";
import { MultiSelect }      from "./Filter.Controls.MultiSelect";
import { Dropdown }         from "./Filter.Controls.Dropdown";
import { Col, Row }         from "./Filter.Controls.style";
import { Icon }             from "assets/Icons_";
import { _project }         from "state/store.projects";
import { getFocus, useGet } from "state/jotai";
import { active_project }   from "state/store.global";
  // import { getProjectData }   from "state/jotai.projectDataHooks";

  // const dropdownData = {
  //   Type: "Dropdown",
  //   Default: [0],
  // };

export const Controls = ({ active, options, setFilters }) => {
  const { pin, pid } = useGet(active_project);
    // const buttonsLabel  = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const iconLookup = getFocus(_project, "IconLookup")?.[pin]?.[pid] ?? [];

  let lookup = {};

    // const lookup = {
    //   'Husleje'       : 'price',
    //   'Månadshyra'    : 'price',
    //   'Størrelse'     : 'area',
    //   'Storlek'       : 'area',
    //   'Etage'         : 'floor',
    //   'Våning'        : 'floor',
    //   'Status'        : 'status',
    //   'Altantype'     : 'balcony',
    //   'Antal værelser': 'rooms',
    //   'Værelser'      : 'rooms',
    //   'Orientering'   : 'orientation',
    //   'Boligtype'     : 'boligtype',
    //   'Rent per month': 'price',
    //   'Size'          : 'area',
    //   'Floor'         : 'floor',
    // }

    // const PossibleValues = getProjectData("unitcategories").map((i, j) => ({
    //   ID: j,
    //   Value: !j
    //     ? buttonsLabel?.filter((btn) => btn?.UILabelProperty === "Boligtype")[0]
    //         ?.Label
    //     : i,
    //   ValueProperty: "Boligtype",
    // }));

  iconLookup?.forEach((icon) => {
    lookup[icon?.Property] = icon?.Label;
  });

    // Not needed for Terrassehaverne project
    // dropdownData.PossibleValues = PossibleValues;
    // dropdownData.Label = buttonsLabel?.filter(
    //   (btn) => btn?.UILabelProperty === "Boligtype"
    // )[0]?.Label;

    // if (!options.filter((i) => i.Type == "Dropdown").length > 0) {
    //   options.push(dropdownData);
    // }

  return (
    <>
      {options.map(({ Type, Label, ...i }, j) => {
        const props = { ...i, active, setFilters, key: j, index: j, Label };
        const icon  = { icon: lookup[Label], color: clr?.icons, size: 40 };

        return (
          <Col key = {j}>
              <div
              style={{
                border       : "0px solid black",
                display      : "flex",
                flexDirection: "column",
                alignItems   : "center",
                height       : 65,
              }}
            >
              <Icon {...icon} />
              <div style = {{ flex: "0 0 5px" }} />
              <div style = {{ position: "relative", top: -7, color: '#fff' }}>{Label}</div>
              </div>
            <Row>
              {Type === "MultiSelect" && <MultiSelect {...props} />}
              {Type === "MultiRange" && <Interval {...props} />}
              {Type === "Boolean"    && <Boolean {...props} />}
              {Type === "Dropdown"   && <Dropdown {...props} />}
            </Row>
          </Col>
        );
      })}
    </>
  );
};
