import { Box, CTA, Low, Separator, Status } from './Card.Content.style'
import { useBreak }                         from 'hooks/useBreak'
import { get }                              from 'components/List/List.Utilities'
import { Text }                             from './Card.Text'
import { Thumbnail }                        from './Card.Content.Thumbnail'
import { Icon }                             from 'assets/Icons_'
import { getFocus, useAtom, useGet }        from 'state/jotai'
import { active_project, favorites }        from 'state/store.global'
import { compares }                         from 'state/store.global'
import { _project }                         from 'state/store.projects'

export const Content = ({unit,thumbnail,color}) => {
  const likes    = useAtom(favorites)[0]
  const setLikes = useAtom(favorites)[1]
  const comps    = useAtom(compares)[0]
  const setComps = useAtom(compares)[1]
  const show     = useBreak('card').toString()
  const sID      = unit.Data.UnitStatusID.Value

  const isMobile = useBreak('md_dn')
  
  const share      = { icon:'share'      , color:clr?.icons, size:isMobile?15:15 }
  const compare    = { icon:'compare3'   , color:clr?.icons, size:isMobile?25:25 }
  const compare_   = { icon:'compare3'   , color:'gray'    , size:isMobile?25:25 }
  const heartempty = { icon:'heart_empty', color:clr?.icons, size:isMobile?28:28 }
  const heartfull  = { icon:'heart_full' , color:'#863E30' , size:isMobile?28:28 }

  const isLiked = likes.includes(unit.UnitID)
  const isComps = comps.includes(unit.UnitID)

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const toggleLike = (e) => {
    if ( sID == 40 ) { return }
    e.stopPropagation()
    const add = [...likes,unit.UnitID]
    const rem = [...likes].filter( i => i !== unit.UnitID )
    setLikes( isLiked ? rem : add )
    log({ action:isLiked 
            ? 'removed_apartment_from_favorites'
            :  'added_apartment_to_favorites'
          , unitId })
  }

  const toggleComp = (e) => {
    if ( sID == 40 ) { return }
    e.stopPropagation()
    const add = [...comps,unit.UnitID]
    const rem = [...comps].filter( i => i !== unit.UnitID )
    const max = comps.length == 3
    setComps( isComps ? rem : ( max ? comps : add ) )
  }

  return (
    <>
      { show == 'true' && <Thumbnail thumbnail={thumbnail}/> }
      <Box>
        <Text unit = {unit}/>
        {/* <div style={{height:12}}/> */}
        <Separator type = {show} color = {color}/>
        {/* <div style={{height:8}}/> */}
        <Low  type                                      = {show}>
        <CTA  onClick                                   = {toggleComp}>
        <Icon {... isComps ? compare_ : compare } color = {'#000'} count = { isComps ? comps.indexOf(unit.UnitID)+1 : 0}/>
            { isComps ? `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Valgt')[0]?.Label }` : `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Sammenlign')[0]?.Label }` }
          </CTA>
          <CTA onClick = {toggleLike}>
          {
                  show                                         == 'true' ? <>
            <Icon {... isLiked ? heartfull : heartempty} color  = {isLiked ? '#863E30' : '#000'} />{ isLiked ? `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'DinFavorit')[0]?.Label }` : `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'GemBolig')[0]?.Label }` }
            </>: <>
              <Icon {...isLiked ? heartfull : heartempty} color = {isLiked ? '#863E30' : '#000'} />{ isLiked ? `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'DinFavorit')[0]?.Label }` : `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'GemBolig')[0]?.Label }` }
            </>
          }
          </CTA>
        </Low>
        <Status color = {get(unit,'UnitColor')}>{ get(unit,'UnitStatusName') }</Status>
      </Box>
    </>
  )
}