import { useState, useRef }               from 'react'
import { Box }                            from './Sort.style'
import { useBreak }                       from 'hooks/useBreak'
import { getProjectData, setProjectData } from 'state/jotai.projectDataHooks'
import { applysorting }                   from 'utilities/utility.sort'
import { Arrow }                          from './Arrow'
import { Icon }                           from 'assets/Icons_'
import { Foldout }                        from './Sort.Foldout'
import Dropdown                           from "components/mui/Dropdown";
import DropdownItem                       from "components/mui/DropdownItem";
import { getFocus, useGet }               from 'state/jotai'
import { active_project }                 from 'state/store.global'
import { _project }                       from 'state/store.projects'

export const Sort_RETIRED = () => {
  
  const [state, setState] = useState(false)
  const isDesktop         = useBreak('md_up')
  const activesort        = getProjectData('activesorting')
  const click             = () => setState(!state)

  const glarr = {
    color: clr.icons,
    icon : 'globe_arrow',
    size : 10,
    wrap : { 
      transform: state ? 'rotate(180deg)': 'rotate(0deg)',
      position : 'relative',
      top      : 2,
    },
  }

  return (
    <>
      <Box onClick = {click}>
        Sorter {isDesktop && activesort.label }<Icon {...glarr}/>
      </Box>
      <Foldout state = {state} setState = {setState}/>
    </>
  )
}

export const Sort = () => {
  const dropdownRef = useRef(null);
  const isDesktop   = useBreak('md_up')

  const options = getProjectData('sorteroptions',0,[])
  const active  = getProjectData('activesorting')
  const units_  = getProjectData('units')
  const raws_   = getProjectData('unitsraw')

  const setSort                           = setProjectData('activesorting')
  const setUnits                          = setProjectData('units')
  const setRaws                           = setProjectData('unitsraw')
  const id                                = active.id
  const { Units, ...rest }                = units_
  const { Units: Raws, ...wrest }         = raws_
  const [selectedLabel, setSelectedLabel] = useState(active.label)
  
  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  
  const handleSelect = ({ID, Label, OrderOn, DefaultDirection}) => {
    const sorting = {
      key      : OrderOn,
      direction: DefaultDirection,
      id       : ID,
      label    : Label,
    }

    setSelectedLabel(Label)

    const units = applysorting(Units, sorting)
    const raws  = applysorting(Raws, sorting)

    setSort(sorting)
    setUnits({ ...rest, Units: units })
    setRaws({ ...wrest, Units: raws })
    
    dropdownRef.current.closeMenu();
  };

  return (
    <Box>
      <Dropdown width={isDesktop? "300px" : "auto"} ref = {dropdownRef} buttonText = { buttonsLabel?.filter( btn => btn.UILabelProperty === 'Sorter')[0]?.Label } selectedLabel = { isDesktop && active?.label }>
        {/* TODO: Niklas - handle sort options */}
        { options.map((option, i) => (
          <DropdownItem
            key      = {i}
            onClick  = {() => handleSelect(option)}
            selected = {option.ID === id}
          >
            {option.Label}
          </DropdownItem>
        ))}
      </Dropdown>
    </Box>
  );
};