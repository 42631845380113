import { motion }                   from 'framer-motion'
import { AnimatePresence }          from 'framer-motion'
import { Icon }                     from 'assets/Icons_'
import { PoweredBy }                from 'components/PoweredBy/PoweredBy'
import { Menu, Tile, Split }        from './Nav.Mobile.Foldout.style'
import { useTranslation }           from 'react-i18next'
import { _project }                 from "state/store.projects";
import { active_project }           from "state/store.global"; 
import { getFocus, useGet }         from "state/jotai";
import { Link }                     from "react-router-dom"

export const Foldout = ({nav,show,setShow}) => {
  const { pin, pid } = useGet(active_project);
  const menus        = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];

  const { i18n:{language} } = useTranslation()
  
  const menu = {
    initial   : { y:'-100%' },
    animate   : { y: 60 },
    exit      : { y:'-100%' },
    transition: { duration:0.3 },
  }

  const menusItems = menus.map( (items, index) => {
    const item = {};
    for (const key in items) {
      if (items.hasOwnProperty(key)) {
        item[key.toLowerCase()] = items[key];
      }
    }
    return item; 
    })

  return (
    <AnimatePresence>
     { show && <Menu 
          as  = {motion.div}
          clr = {clr}
          {...menu}>
          <div style = {{flex:1}}/>
          { 
            menusItems.map(({url,text,icon},j) => {
            
              const ico = { href:url, icon, size:30, color:clr.icons }
                // console.log(text,'ico')
              return ( 
                <div key = {text} onClick={()=>setShow(false)}>
                { j > 0 && <Split clr={clr}/> }
                <Link to  = {url} key = {text} style = {{textDecoration:'none'}}>
                <Tile clr = {clr}>
                    <Icon {...ico}/>
                    { text }
                  </Tile>
                </Link>
              </div>
              )
            })
          }
          <div style = {{flex:1}}/>
          <PoweredBy  clr={clr.buttons_text}/>
        </Menu>
      }
    </AnimatePresence>
  )
}
