import { Box, Divider, Stack }                     from "@mui/material";
import PropTypes                                   from "prop-types";
import React                                       from "react";
import ButtonCompare                               from "./ButtonCompare";
import ButtonFavorite                              from "./ButtonFavorite";
import Unit                                        from "./Unit.model";
import { useTranslation }                          from "react-i18next";
import { useNavigate }                             from "react-router-dom";
import { animateScroll }                           from "react-scroll";
import { getFocus, get as projectGet, useGet }     from '../../state/jotai'
import { _project }                                from "state/store.projects";
import useUnitPage                                 from "./useUnitPage";
import { get }                                     from "components/Favorites/Favorites.Utilities";
import { active_project, previousUrl }             from "state/store.global";
import { useLocation }                             from 'react-router-dom';
import { useAtom }                                 from "jotai";

const UnitCard = ({ unit, layout, onClick }) => {
  const location = useLocation();

  const [, setPreviousUrl] = useAtom(previousUrl);
  const { pin, pid }       = useGet(active_project)
  const buttonsLabel       = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const id             = unit.getId();
  const status         = unit.getStatus();
  const floorplan      = unit.getFloorplanUrl(true);
  const projeknr       = `${ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label } ${unit.getValue("Projeknr")}`;
  const type           = unit.getValue("SalesTypeName");
  const address        = unit.getAddress();
  const townAndZipcode = unit.getTownZipCode();
  const price          = unit.getPrice();
  
  const pricePf = unit.isRent() ? `${ get(unit.data,'LejelejlighedsText') }` : "Kontantpris";
  const area    = `${unit.getValue("Areal")} ${unit.getPostfix("Areal")}`;
  const text    = projectGet(_project, 'cardtext').flatMap((data) => {
    return data?.filter(p => p.Property === "VaerelserAntal");
  })[0]?.Postfix
  const rooms          = `${unit.getValue("VaerelserAntal")} ${text}`;
  const uderum         = `${unit.getValue("Uderum")}`;
  const navigateTo     = useNavigate();
  const [refContainer] = useUnitPage();

  const { i18n: { language } } = useTranslation();

  const handleClick = () => {
    setPreviousUrl(location.pathname)
    navigateTo(`/${language}/unit/${id}`);

    animateScroll.scrollToTop({
      container: refContainer,
      duration : 500
    });
    log({ 
      action: 'clicked_apartment_on_listview',
      unitId: id
    })
  };

  return (
    <Box
      sx={{
        backgroundColor: `${status.color}22`,
        borderTop      : "grid" === layout ? `6px solid ${status.color}`        : "none",
        borderLeft     : "list" === layout ? `8px solid ${status.color}`        : "none",
        borderRadius   : "list" === layout ? "7px 10px 10px 7px"                : "6px 6px 0 0",
        display        : "flex",
        height         : { sx: 'auto', md: "list" === layout ? "167px" : "auto"},
        flexDirection  : "grid" === layout ? "column"                           : "row",
        "&:hover"      : {
          backgroundColor: `${status.color}60`,
          cursor         : "pointer"
        }
      }}
      onClick = {handleClick}
    >
      {/* image */}
      <Box
        sx={{
          display: {
            xs: "list" === layout ? "none" : "block", sm: "list" === layout ? "flex" : "block"
          },
          width   : "grid" === layout ? "99.5%" : "auto",
          maxWidth: "grid" === layout ? "100%" : "50%",
          margin  : "list" === layout ? "unset" : "auto",
          flex    : "list" === layout ? "0 0 260px" : "none",
          padding : "list" === layout ? "2px 0" : "unset"
        }}
      >
        <Box
          component = "img"
          src       = {floorplan}
          sx        = {{
            height : "100%",
            width  : "100%",
            display: "block"
          }}
          alt = "floorplan"
        />
      </Box>

      <Box
        sx={{
          position  : "relative",
          lineHeight: {
            xs: 1.5,
          },
          width   : "grid" === layout ? "auto": "100%",
          flexGrow: 1,
        }}
      >
      <Box sx={{ position: "relative", 
      padding: { md: "list" === layout ? "10px 15px 0 15px" : "15px",  xs: "list" === layout ? "10px 15px 0 15px": "15px", } }}>
      <Box
          sx={{
            position  : "absolute",
            right     : 15,
            top       : { xs: "grid" === layout ? "15px" : "10px"},
            fontWeight: 700,
            color     : status.color,
          }}
        >
          {status.label}
        </Box>

        <Box sx = {{ fontWeight: 700 }} >{projeknr}</Box>
        <div>{type}</div>
        <div>{address}, {townAndZipcode}</div>
        <div>
          {pricePf === '' ? status.label : price.trim()} {pricePf.toLowerCase()}
        </div>
        <div>
          {area} &nbsp;•&nbsp; {rooms} &nbsp;•&nbsp; {uderum}
        </div>

        {/* actions */}
      </Box>
       <Divider        sx        = {{ width: { xs: "95%", lg: "calc(100% - 15px)" }, mb: 0, marginLeft: "auto", marginRight: { xs: "auto", lg: "unset"} }} />
       <Stack          direction = "row" justifyContent = "start" gap = "15px" sx = {{ padding: "0 15px", justifyContent: { xs: "space-between", md: "start"} }}>
       <ButtonCompare  unitId    = {id} />
       <ButtonFavorite unitId    = {id} />
        </Stack>
      </Box>
    </Box>
  );
};

UnitCard.propTypes = {
  unit   : PropTypes.instanceOf(Unit),
  layout : PropTypes.oneOf(["list", "grid"]),
  onClick: PropTypes.func,
};

UnitCard.defaultProps = {
  layout : "grid",
  onClick: () => {},
};

export default UnitCard;
